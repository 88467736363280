import {
  Alert,
  Button,
  Column,
  Heading,
  SectionHeading,
  useToast,
  Text,
} from "@hightouchio/ui";
import { useOutletContext, useParams } from "src/router";
import { useMemo } from "react";

import {
  useEnableDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineMessageAndCollectionsMutation,
} from "src/graphql";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Variables } from "src/pages/decision-engines/flows/flow/messages/message/components/variables";
import { Subjects } from "src/pages/decision-engines/flows/flow/messages/message/components/subjects";
import { CollectionSelect } from "src/pages/decision-engines/flows/flow/messages/message/components/collection-select";
import { FlowMessageContext } from ".";
import { Card } from "src/components/card";
import { MessageCampaign } from "./components/campaign";
import { SendLimit } from "./components/send-limit";
import { Timeline } from "./components/timeline";
import { CampaignAttribution } from "./components/campaign-attribution";
import { Tags } from "./components/tags";
import { getKeyValueArray, getKeyValueObject } from "src/utils/key-value";

export const MessageConfiguration = () => {
  const { flowMessage, engine, flow } = useOutletContext<FlowMessageContext>();

  const currentCollections = useMemo(
    () =>
      flowMessage.message.collections.map((c) => {
        return {
          id: c.decision_engine_collection.id,
          enabled: true,
          item_count: c.item_count,
        };
      }),
    [flowMessage.message.collections],
  );

  const updateMutation = useUpdateDecisionEngineMessageAndCollectionsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const collectionsToUpsert = data.collections
        .filter((c) => c.enabled)
        .map((c) => ({
          decision_engine_message_id: flowMessage.message.id,
          decision_engine_collection_id: c.id,
          item_count: c.item_count,
        }));
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        flowMessage: {
          config: data.flowMessageConfig,
        },
        message: {
          config: data.config,
          variables: [
            ...data.variables,
            {
              name: "subject",
              values: data.subject,
            },
          ],
          tags: getKeyValueObject(data.tags),
        },
        collectionsToUpsertIds: collectionsToUpsert.map(
          (c) => c.decision_engine_collection_id,
        ),
        collectionsToUpsert,
      });
    },
    values: {
      tags: getKeyValueArray(flowMessage.message.tags),
      flowMessageConfig: flowMessage.config,
      config: flowMessage.message.config,
      variables:
        flowMessage.message.variables?.filter((v) => v.name !== "subject") ??
        [],
      subject:
        flowMessage.message.variables?.find((v) => v.name === "subject")
          ?.values ?? [],
      collections:
        engine.collections?.map((collection) => {
          const existingCollection = currentCollections.find(
            (c) => c.id === collection.id,
          );
          return {
            id: collection.id,
            name: collection.collection.name,
            enabled: !!existingCollection,
            item_count: existingCollection?.item_count ?? 1,
          };
        }) ?? [],
    },
  });

  return (
    <Form form={form}>
      <Column flex={1} p={6} gap={6} maxW="2xl" mx="auto">
        <Heading>Message configuration</Heading>
        <DraftCard />
        <Card>
          <MessageCampaign channel={flowMessage.message.channel} />
        </Card>
        <Card>
          <Subjects />
        </Card>
        <Card>
          <CollectionSelect />
        </Card>
        <Card>
          <Variables />
        </Card>
        <Card>
          <Tags />
        </Card>
        <Card>
          <SendLimit />
        </Card>
        <Card>
          <Timeline />
        </Card>
        <Card>
          <CampaignAttribution channel={flowMessage.message.channel} />
        </Card>
      </Column>
      <ActionBar fit>
        <FormActions />
      </ActionBar>
    </Form>
  );
};

const DraftCard = () => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const { toast } = useToast();
  const { flowId } = useParams();
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();
  const isInitialized = Boolean(flowMessage.segment_id);

  if (isInitialized) {
    return null;
  }

  return (
    <Card flex={1} gap={4} alignItems="flex-start">
      {enableMutation.data &&
        enableMutation.data.enableDecisionEngineFlowMessage.__typename ===
          "DecisionEngineError" && (
          <Alert
            variant="inline"
            type="error"
            title="Unable to initialize message"
            message={enableMutation.data?.enableDecisionEngineFlowMessage.error}
          />
        )}
      <Column>
        <SectionHeading>Draft message</SectionHeading>
        <Text>
          Initializing will generate a model and a sync and make it eligible to
          be sent.
        </Text>
      </Column>
      <Button
        isLoading={enableMutation.isLoading}
        onClick={async () => {
          try {
            const response = await enableMutation.mutateAsync({
              messageId: flowMessage.message.id,
              flowId: flowId!,
            });
            if (
              response.enableDecisionEngineFlowMessage.__typename ===
              "DecisionEngineSuccess"
            ) {
              toast({
                id: "enable-message",
                title: "Message initialized and enabled",
                variant: "success",
              });
            } else {
              toast({
                id: "enable-message",
                title: "Error initializing message",
                variant: "error",
                message: response.enableDecisionEngineFlowMessage.error,
              });
            }
          } catch (e) {
            toast({
              id: "enable-message",
              title: "Error initializing message",
              variant: "error",
            });
          }
        }}
      >
        Initialize and enable
      </Button>
    </Card>
  );
};
