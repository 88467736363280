import { Navigate, useOutletContext } from "src/router";
import { Page } from "src/components/layout";
import { DecisionEngine, OutletContext } from ".";
import {
  Text,
  Column,
  Row,
  StatusBadge,
  Alert,
  FormField,
  TextInput,
  Select,
  Heading,
  Button,
  NumberInput,
  useToast,
  ButtonGroup,
  Spinner,
  ConfirmationDialog,
  Paragraph,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Combobox,
  Textarea,
  Checkbox,
} from "@hightouchio/ui";
import { Editor } from "src/components/editor";
import json5 from "json5";
import { Card } from "src/components/card";
import { Value } from "@sinclair/typebox/value";
import {
  DecisionEngineStatus,
  ModelConfigurationTypebox,
} from "@hightouch/lib/customer-data/decision-engine/types";
import {
  useAnalyzeDecisionEngineTagsForMessageQuery,
  useEligibleTargetAudiencesQuery,
  useGetTagsForMessagesBackgroundQuery,
  useResetAttributionStateMutation,
  useResetStateMutation,
  useDryRunDecisionEngineMutation,
  useRunSqlResultQuery,
  useSetDecisionEngineCollectionConfigMutation,
  useSetDecisionEngineConfigMutation,
  useSetDecisionEngineOutcomeConfigMutation,
  useUpdateDecisionEngineFlowMutation,
  useUpdateDecisionEngineMutation,
  useValidateDecisionEngineConfigQuery,
} from "src/graphql";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { useUser } from "src/contexts/user-context";
import { useEffect, useState } from "react";

export const Admin = () => {
  const { user } = useUser();
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const [resetStateOpen, setResetStateOpen] = useState<boolean>(false);
  const [resetAttributionStateOpen, setResetAttributionStateOpen] =
    useState<boolean>(false);
  const updateMutation = useUpdateDecisionEngineMutation();
  const validateQuery = useValidateDecisionEngineConfigQuery({
    decisionEngineId: engine.id,
  });
  const resetMutation = useResetStateMutation();
  const resetAttributionMutation = useResetAttributionStateMutation();

  const isValid =
    validateQuery.data?.validateDecisionEngineConfig.__typename ===
    "DecisionEngineSuccess";

  if (!user?.can_impersonate) {
    return <Navigate to="/ai/flows" />;
  }

  return (
    <Page title="Admin">
      <Column gap={6}>
        {validateQuery.data?.validateDecisionEngineConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            title="Validation error"
            message={JSON.stringify(
              validateQuery.data?.validateDecisionEngineConfig.error,
            )}
          />
        )}
        <Row gap={4} align="center" justify="space-between">
          <Row gap={4} align="center">
            <Heading size="xl">AI Admin</Heading>
            {validateQuery.isFetching ? (
              <Spinner />
            ) : (
              <StatusBadge variant={isValid ? "success" : "error"}>
                {isValid ? "VALID" : "INVALID"}
              </StatusBadge>
            )}
          </Row>

          <ButtonGroup>
            {engine.status === DecisionEngineStatus.PENDING ? (
              <Button
                variant="primary"
                isDisabled={!isValid}
                onClick={async () => {
                  try {
                    await updateMutation.mutateAsync({
                      id: engine.id,
                      input: { status: DecisionEngineStatus.READY },
                    });
                  } catch (error) {
                    toast({
                      id: "ready",
                      title: "Error",
                      message: error.message,
                      variant: "error",
                    });
                  }
                }}
              >
                Ready engine
              </Button>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    try {
                      await updateMutation.mutate({
                        id: engine.id,
                        input: { status: DecisionEngineStatus.PENDING },
                      });
                    } catch (error) {
                      toast({
                        id: "ready",
                        title: "Error",
                        message: error.message,
                        variant: "error",
                      });
                    }
                  }}
                >
                  Unready engine
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setResetStateOpen(true)}
                  isDisabled={resetMutation.isLoading}
                >
                  Reset warehouse state
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setResetAttributionStateOpen(true)}
                  isDisabled={resetAttributionMutation.isLoading}
                >
                  Reset attribution state
                </Button>
              </>
            )}
            <Button
              isLoading={validateQuery.isFetching}
              onClick={() => {
                validateQuery.refetch();
              }}
            >
              Validate
            </Button>
          </ButtonGroup>
        </Row>
        <Tabs>
          <TabList>
            <Tab>Engine</Tab>
            <Tab>Flows</Tab>
            <Tab>Outcomes</Tab>
            <Tab>Collections</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Engine />
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.flows.sort().map((flow) => (
                  <Flow key={flow.id} flow={flow} />
                ))}
              </Column>
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.outcomes.sort().map((outcome) => (
                  <Outcome key={outcome.id} outcome={outcome} />
                ))}
              </Column>
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.collections.sort().map((collection) => (
                  <Collection key={collection.id} collection={collection} />
                ))}
              </Column>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <ConfirmationDialog
          isOpen={resetAttributionStateOpen}
          title="Reset attribution state"
          confirmButtonText="Reset attribution state"
          variant="danger"
          onClose={() => setResetAttributionStateOpen(false)}
          onConfirm={async () => {
            resetAttributionMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the attribution state? You won't be
            able to undo this.
          </Paragraph>
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={resetStateOpen}
          title="Reset state"
          confirmButtonText="Reset state"
          variant="danger"
          onClose={() => setResetStateOpen(false)}
          onConfirm={async () => {
            resetMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the state? You won't be able to undo
            this.
          </Paragraph>
        </ConfirmationDialog>
      </Column>
    </Page>
  );
};

const DryRun = () => {
  const { engine } = useOutletContext<OutletContext>();
  const dryRunEngine = useDryRunDecisionEngineMutation();
  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const [actionModelConfigError, setActionModelConfigError] = useState<
    string | undefined
  >(undefined);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      // NOTE: the resolver also supports overriding collection model configs, but we haven't
      // implemented that here because it's not immediately necessary.
      await dryRunEngine.mutateAsync({
        id: engine.id,
        overrideAudienceId: data.audience_id.toString(),
        actionModelConfiguration: data.actionModelConfiguration
          ? json5.parse(data.actionModelConfiguration)
          : undefined,
        forceAllUsers: data.forceAllUsers,
        messageCompletionIntervalDays:
          data.messageCompletionIntervalDays != null
            ? Number(data.messageCompletionIntervalDays)
            : undefined,
      });
    },
  });

  // Basically used to do validation on the model config
  async function modelConfigurationChangeHandler(
    e: string,
    onChange: (value: string) => void,
  ) {
    // We allow an empty config
    if (e.length == 0) {
      setActionModelConfigError(undefined);
      return;
    }

    try {
      const jsonConfig = json5.parse(e);
      const errors = Array.from(
        Value.Errors(ModelConfigurationTypebox, jsonConfig),
      );
      if (errors.length > 0) {
        setActionModelConfigError(
          errors
            .map((e) => `Error with property ${e.path}: ${e.message}`)
            .join("\n"),
        );
        return;
      }

      onChange(e);
      setActionModelConfigError(undefined);
    } catch (error) {
      console.error("Parsing error: ", error);
      setActionModelConfigError(error.message);
    }
  }

  return (
    <Form form={form}>
      <Card maxHeight="800px" overflow="hidden" gap={4}>
        <Column>
          <Heading>Dry Run</Heading>
        </Column>
        <Controller
          name="audience_id"
          render={({ field }) => (
            <FormField label="Target override audience">
              <Combobox
                {...field}
                isLoading={modelsQuery.isLoading}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name}
                options={modelsQuery.data ?? []}
              />
            </FormField>
          )}
        />

        <Controller
          name="actionModelConfiguration"
          render={({ field }) => (
            <FormField
              label="Action model configuration overrides"
              error={actionModelConfigError}
            >
              <Column overflow="hidden" flex={1} height="300px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) =>
                    modelConfigurationChangeHandler(e, field.onChange)
                  }
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="forceAllUsers"
          render={({ field }) => (
            <FormField label="Force all users">
              <Checkbox
                isChecked={field.value}
                isDisabled={false}
                onChange={field.onChange}
              />
            </FormField>
          )}
        />

        <Controller
          name="messageCompletionIntervalDays"
          render={({ field }) => (
            <FormField label="Days to complete message">
              <TextInput {...field} type="number" />
            </FormField>
          )}
        />

        <Row>
          <Button
            variant="primary"
            isDisabled={
              dryRunEngine.isLoading ||
              modelsQuery.isLoading ||
              actionModelConfigError !== undefined
            }
            onClick={async () => {
              await form.submit();
            }}
          >
            Run dry run
          </Button>
        </Row>
      </Card>
    </Form>
  );
};

const Engine = () => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        config: {
          name: data.name,
          feature_model_id: Number(data.feature_model_id),
          user_feature_schema: json5.parse(data.user_feature_schema),
          output_schema: data.output_schema,
          attribution: data.attribution,
          message_completion_interval_days:
            data.message_completion_interval_days
              ? Number(data.message_completion_interval_days)
              : null,
          action_model_configuration: data.action_model_configuration
            ? json5.parse(data.action_model_configuration)
            : null,
        },
      });
    },
    values: {
      attribution: engine.config.attribution ?? {
        window: { unit: null, value: 1 },
      },
      feature_model_id: engine.config.feature_model_id ?? "",
      name: engine.config.name ?? "",
      output_schema: engine.config.output_schema ?? "",
      message_completion_interval_days:
        engine.config.message_completion_interval_days ?? null,
      user_feature_schema: engine.config.user_feature_schema
        ? JSON.stringify(engine.config.user_feature_schema, null, 2)
        : "",
      action_model_configuration: engine.config.action_model_configuration
        ? JSON.stringify(engine.config.action_model_configuration, null, 2)
        : "",
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <Card
          maxHeight="800px"
          overflow="hidden"
          footer={<FormActions />}
          gap={4}
        >
          <Row align="center" gap={2}>
            <Heading>Engine</Heading>
            <StatusBadge
              variant={
                engine.status === DecisionEngineStatus.TRAINING
                  ? "processing"
                  : engine.status === DecisionEngineStatus.READY
                    ? "success"
                    : "inactive"
              }
            >
              {engine.status.toUpperCase()}
            </StatusBadge>
            <StatusBadge variant={engine.enabled ? "success" : "inactive"}>
              {engine.enabled ? "ENABLED" : "DISABLED"}
            </StatusBadge>
          </Row>
          <Text size="sm" color="text.secondary">
            {engine.id}
          </Text>

          {mutation.data?.setDecisionEngineConfig.__typename ===
            "DecisionEngineError" && (
            <Alert
              type="error"
              variant="inline"
              title="Error"
              message={JSON.stringify(
                mutation.data.setDecisionEngineConfig.error,
              )}
            />
          )}

          <Row gap={8} overflow="hidden">
            <Column gap={4}>
              <Controller
                name="name"
                render={({ field }) => (
                  <FormField label="Name">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="feature_model_id"
                render={({ field }) => (
                  <FormField label="Feature Model ID">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="output_schema"
                render={({ field }) => (
                  <FormField label="Output Schema">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="message_completion_interval_days"
                render={({ field }) => (
                  <FormField label="Days to complete message">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="attribution.window.unit"
                render={({ field }) => (
                  <FormField label="Attribution Window Unit">
                    <Select
                      {...field}
                      options={[
                        "minute",
                        "hour",
                        "day",
                        "week",
                        "month",
                        "year",
                      ]}
                      optionValue={(value) => value}
                      optionLabel={(value) => value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="attribution.window.value"
                render={({ field }) => (
                  <FormField label="Attribution Window Value">
                    <NumberInput {...field} />
                  </FormField>
                )}
              />
            </Column>
            <Column flex={1} gap={4}>
              <Controller
                name="user_feature_schema"
                render={({ field }) => (
                  <Column overflow="hidden" flex={1}>
                    <Text fontWeight="medium" mb={1}>
                      user_feature_schema
                    </Text>
                    <Editor language="json" bg="base.background" {...field} />
                  </Column>
                )}
              />
              <Controller
                name="action_model_configuration"
                render={({ field }) => (
                  <Column overflow="hidden" flex={1}>
                    <Text fontWeight="medium" mb={1}>
                      action_model_configuration
                    </Text>
                    <Editor language="json" bg="base.background" {...field} />
                  </Column>
                )}
              />
            </Column>
          </Row>
        </Card>

        <DryRun />

        <ChannelTags />

        <MessageTags />
      </Column>
    </Form>
  );
};

const Collection = ({
  collection,
}: {
  collection: DecisionEngine["collections"][0];
}) => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineCollectionConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        collectionId: collection.id,
        schema: json5.parse(data.schema),
      });
    },
    values: {
      schema: JSON.stringify(collection.config?.schema ?? {}, null, 2),
    },
  });

  return (
    <Form form={form}>
      <Card
        maxHeight="800px"
        overflow="hidden"
        footer={<FormActions />}
        gap={4}
      >
        <Column>
          <Heading>{collection.collection.name}</Heading>
          <Text size="sm" color="text.secondary">
            {collection.id}
          </Text>
        </Column>

        {mutation.data?.setDecisionEngineCollectionConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            variant="inline"
            title="Error"
            message={JSON.stringify(
              mutation.data.setDecisionEngineCollectionConfig.error,
            )}
          />
        )}

        <Column flex={1} overflow="hidden">
          <Controller
            name="schema"
            render={({ field }) => <Editor language="json" {...field} />}
          />
        </Column>
      </Card>
    </Form>
  );
};

const Outcome = ({ outcome }: { outcome: DecisionEngine["outcomes"][0] }) => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineOutcomeConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        outcomeId: outcome.id,
        ...data,
      });
    },
    values:
      // If unspecified, omit the metadataColumn so it gets set to null in the DB.
      outcome.attribution?.metadata_column !== ""
        ? {
            metadataColumn: outcome.attribution?.metadata_column,
          }
        : {},
  });

  return (
    <Form form={form}>
      <Card footer={<FormActions />} gap={4}>
        <Column>
          <Heading>{outcome.name}</Heading>
          <Text size="sm" color="text.secondary">
            {outcome.id}
          </Text>
        </Column>

        {mutation.data?.setDecisionEngineOutcomeConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            variant="inline"
            title="Error"
            message={JSON.stringify(
              mutation.data.setDecisionEngineOutcomeConfig.error,
            )}
          />
        )}

        <Controller
          name="metadataColumn"
          control={form.control}
          render={({ field }) => (
            <FormField label="metadataColumn">
              <TextInput {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};

const Flow = ({ flow }: { flow: DecisionEngine["flows"][0] }) => {
  const { engine } = useOutletContext<OutletContext>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const updateMutation = useUpdateDecisionEngineFlowMutation();

  const resetMutation = useResetStateMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateMutation.mutateAsync({
        id: flow.id,
        input: {
          config: {
            ...data.config,
            flow_start_date: data.config.flow_start_date
              ? new Date(data.config.flow_start_date).toISOString()
              : null,
          },
        },
      });
    },
    values: {
      config: flow.config,
    },
  });

  function formatDateToDatetimeLocal(date: Date) {
    const pad = (num) => String(num).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return (
    <>
      <Form form={form}>
        <Card
          footer={
            <Row justify="space-between" w="100%">
              <FormActions />
              <ButtonGroup>
                {flow.status === DecisionEngineStatus.TRAINING && (
                  <Button
                    size="lg"
                    onClick={async () => {
                      await updateMutation.mutate({
                        id: flow.id,
                        input: {
                          status: DecisionEngineStatus.READY,
                        },
                      });
                    }}
                    isLoading={updateMutation.isLoading}
                  >
                    Ready flow
                  </Button>
                )}
                <Button
                  size="lg"
                  variant="danger"
                  onClick={onOpen}
                  isDisabled={resetMutation.isLoading}
                >
                  Reset Warehouse state
                </Button>
              </ButtonGroup>
            </Row>
          }
          gap={4}
        >
          <Column>
            <Heading>{flow.name}</Heading>
            <Text size="sm" color="text.secondary">
              {flow.id}
            </Text>
          </Column>

          <Controller
            name="config.flow_start_date"
            control={form.control}
            render={({ field }) => (
              <FormField label="Start date">
                <TextInput
                  type="datetime-local"
                  {...field}
                  value={
                    field.value
                      ? formatDateToDatetimeLocal(new Date(field.value))
                      : ""
                  }
                />
              </FormField>
            )}
          />
        </Card>
      </Form>

      <ConfirmationDialog
        isOpen={isOpen}
        title="Reset state"
        confirmButtonText="Reset state"
        variant="danger"
        onClose={onClose}
        onConfirm={async () => {
          resetMutation.mutate({
            decisionEngineId: engine.id,
            flowId: flow.id,
          });
        }}
      >
        <Paragraph>
          Are you sure you want to reset the state? You won't be able to undo
          this.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const ChannelTags = () => {
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [channel, setChannel] = useState("");

  const { data: jobId } = useGetTagsForMessagesBackgroundQuery(
    {
      decisionEngineChannelId: channel,
    },
    {
      select: (data) => data.getTagsForMessagesBackground,
      enabled: channel?.length > 0,
    },
  );

  const [tags, setTags] = useState<any>();

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = JSON.parse(
            (
              Object.values(data.backgroundPreviewQueryResult.rows[0]) as any
            )[0]["choices"][0]["messages"],
          );
          setTags(parsedTags);
        }
        setShouldPoll(false);
        setChannel("");
      },
    },
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setChannel(data.channel);
    },
  });

  return (
    <>
      <Form form={form}>
        <Card gap={4}>
          <Column>
            <Heading>Get tags for channel</Heading>
          </Column>
          <Controller
            name="channel"
            render={({ field }) => (
              <FormField label="Channels">
                <Select
                  {...field}
                  options={engine.channels}
                  optionValue={(value) => value.id}
                  optionLabel={(value) => value.type}
                />
              </FormField>
            )}
          />

          <Row>
            <Button variant="primary" onClick={form.submit} size="lg">
              Get tags
            </Button>
          </Row>

          {tags && JSON.stringify(tags, null, 2)}
        </Card>
      </Form>
    </>
  );
};

const MessageTags = () => {
  const { messages } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTags, setMessageTags] = useState<any>();
  const [tags, setTags] = useState("");

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setMessage(data.message);
      setTags(JSON.parse(data.tags));
    },
  });

  const { data: jobId } = useAnalyzeDecisionEngineTagsForMessageQuery(
    {
      messageId: message,
      tags,
    },
    {
      select: (data) => data.analyzeDecisionEngineTagsForMessage,
      enabled: message?.length > 0,
    },
  );

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = JSON.parse(
            (
              Object.values(data.backgroundPreviewQueryResult.rows[0]) as any
            )[0]["choices"][0]["messages"],
          );
          setMessageTags(parsedTags);
        }
        setShouldPoll(false);
        setMessage("");
      },
    },
  );

  return (
    <Form form={form}>
      <Card gap={4}>
        <Column>
          <Heading>Tags for Message</Heading>
        </Column>

        <Controller
          name="message"
          render={({ field }) => (
            <FormField label="Message">
              <Select
                {...field}
                options={messages}
                optionValue={(value) => value.id}
                optionLabel={(value) => value.name}
              />
            </FormField>
          )}
        />

        <Controller
          name="tags"
          render={({ field }) => (
            <FormField label="Tags">
              <Textarea {...field} />
            </FormField>
          )}
        />

        <Row>
          <Button variant="primary" onClick={form.submit} size="lg">
            Get tags
          </Button>
        </Row>

        {messageTags && JSON.stringify(messageTags, null, 2)}
      </Card>
    </Form>
  );
};
