import {
  Box,
  CloseIcon,
  Column,
  EditableHeading,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  PauseIcon,
  PlayIcon,
  Row,
  Text,
  useToast,
  Badge,
} from "@hightouchio/ui";
import {
  useNavigate,
  useOutletContext,
  useParams,
  Outlet,
  Navigate,
} from "src/router";
import { useMemo } from "react";
import { captureException } from "@sentry/react";

import { Drawer } from "src/components/drawer";
import {
  DecisionEngineMessageQuery,
  useDisableDecisionEngineFlowMessageMutation,
  useEnableDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineMessageMutation,
} from "src/graphql";
import { Channel } from "src/pages/decision-engines";
import { FlowContext } from "src/pages/decision-engines/flows/flow";
import { DestinationResourceLink } from "./components/campaign-link";
import { useFlags } from "launchdarkly-react-client-sdk";
import { RouteTabs } from "src/components/route-tabs";

export type Message = Omit<
  NonNullable<DecisionEngineMessageQuery["decision_engine_messages_by_pk"]>,
  "channel"
> & {
  channel: Channel;
};

export type FlowMessageContext = FlowContext & {
  flowMessage: FlowContext["flow"]["messages"][0];
};

export const FlowMessage = () => {
  const { messageId: id } = useParams<{
    messageId: string;
  }>();
  const flowContext = useOutletContext<FlowContext>();
  const { decisionEngineDemo } = useFlags();

  const flowMessage = flowContext.flow.messages.find(
    (m) => m.message.id === id,
  )!;

  const context = useMemo(
    () => ({
      ...flowContext,
      flowMessage,
    }),
    [flowContext, flowMessage],
  );

  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/ai/flows/${flowContext.flow.id}`);
  };

  if (!flowMessage) {
    return <Navigate to=".." />;
  }

  return (
    <Drawer isOpen onClose={onClose} size="xl">
      <Column gap={2} p={6}>
        <Row gap={2} align="center" justify="space-between">
          <Heading message={flowMessage.message} />

          <Row align="center" gap={4}>
            <ActionMenu
              flowMessage={flowMessage}
              flowId={flowContext.flow.id}
            />
            <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
          </Row>
        </Row>

        <Row gap={2} align="center">
          <Box borderRight="2px" borderColor="base.border" pr={2}>
            {!flowMessage.segment_id ? (
              <Badge variant="subtle">Draft</Badge>
            ) : flowMessage.enabled ? (
              <Badge variant="success">Enabled</Badge>
            ) : (
              <Badge variant="subtle">Disabled</Badge>
            )}
          </Box>
          <DestinationResourceLink
            resourceId={
              flowMessage.config.resourceId ?? flowMessage.config.campaignId
            }
            channel={flowMessage.message.channel}
          />
        </Row>
      </Column>

      <RouteTabs
        px={6}
        depth={6}
        tabs={[
          {
            path: "configuration",
            title: "Configuration",
          },
          {
            path: "integration",
            title: "Integration",
          },
          {
            path: "preview",
            title: "Preview",
          },
          {
            path: "cortex",
            title: "Cortex",
            isHidden: !decisionEngineDemo,
          },
        ]}
      />
      <Column flex={1} bg="gray.50" overflow="auto">
        <Outlet context={context} />
      </Column>
    </Drawer>
  );
};

const ActionMenu = ({
  flowId,
  flowMessage,
}: {
  flowId: string;
  flowMessage: FlowMessageContext["flowMessage"];
}) => {
  const { toast } = useToast();
  const isInitialized = Boolean(flowMessage.segment_id);
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();
  const disableMutation = useDisableDecisionEngineFlowMessageMutation();

  if (!isInitialized) {
    return null;
  }

  return (
    <Menu>
      <MenuActionsButton />
      <MenuList>
        {flowMessage.enabled ? (
          <MenuItem
            onClick={async () => {
              try {
                const response = await disableMutation.mutateAsync({
                  flowId,
                  messageId: flowMessage.message.id,
                });
                if (
                  response.disableDecisionEngineFlowMessage.__typename ===
                  "DecisionEngineSuccess"
                ) {
                  toast({
                    id: "disable-message",
                    title: "Message disabled",
                    variant: "success",
                  });
                } else {
                  toast({
                    id: "disable-message",
                    title: "Error disabling message",
                    variant: "error",
                    message:
                      response.disableDecisionEngineFlowMessage.error ?? "",
                  });
                }
              } catch (e) {
                toast({
                  id: "disable-message",
                  title: "Error disabling message",
                  variant: "error",
                  message: e.message,
                });
              }
            }}
            icon={PauseIcon}
          >
            <Column>
              <Text>Disable</Text>
              <Text color="text.secondary" size="sm">
                Message will not be sent
              </Text>
            </Column>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={async () => {
              try {
                const response = await enableMutation.mutateAsync({
                  flowId,
                  messageId: flowMessage.message.id,
                });
                if (
                  response.enableDecisionEngineFlowMessage.__typename ===
                  "DecisionEngineSuccess"
                ) {
                  toast({
                    id: "enable-message",
                    title: "Message enabled",
                    variant: "success",
                  });
                } else {
                  toast({
                    id: "enable-message",
                    title: "Error enabling message",
                    variant: "error",
                    message: response.enableDecisionEngineFlowMessage.error,
                  });
                }
              } catch (e) {
                toast({
                  id: "enable-message",
                  title: "Error enabling message",
                  variant: "error",
                  message: e.message,
                });
              }
            }}
            icon={PlayIcon}
          >
            <Column>
              <Text>Enable</Text>
              <Text color="text.secondary" size="sm">
                Message will be eligible for sending
              </Text>
            </Column>
          </MenuItem>
        )}
        {/* 
         TODO: Fix Hightouch UI dialog in drawers
      <DeleteButton
        label="message"
        variant="menu"
        onSuccess={onClose}
        onDelete={async () =>
          deleteMutation.mutateAsync({ id: flowMessage.message.id })
        }
      /> */}
      </MenuList>
    </Menu>
  );
};

const Heading = ({ message }) => {
  const { toast } = useToast();
  const updateMutation = useUpdateDecisionEngineMessageMutation();

  const updateName = async (name: string) => {
    try {
      await updateMutation.mutateAsync({
        id: message?.id ?? "",
        input: {
          name,
        },
      });
      toast({
        id: "name-updated",
        title: "Message name saved",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "name-updated",
        title: "Failed to save message name",
        variant: "error",
      });
    }
  };

  return <EditableHeading value={message?.name ?? ""} onChange={updateName} />;
};
